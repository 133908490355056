<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card foot-print">
			<div slot="header" class="clearfix">
				<span>我的商品</span>
			</div>

			<div v-loading="loading" v-if="cargoList != ''">
				<div class="goods-info">
					<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id" @click="$router.pushToTab({ path: '/goodsdetail?id=' + item.sku_id })">
												
						<el-row>
						  <el-col :span="4">
							  <div class="img_div">
								  <img class="img-wrap" :src="$img(item.goods_image)" @error="imageError(index)" />
							  </div>
						  </el-col>
						  <el-col :span="16">
							  <div class="goods-name">{{ item.goods_name }}</div>
						  </el-col>
						  <el-col :span="3">
							  <div class="price_div">
								  <div class="price-wrap">
								  	<div class="price">
								  		<p>￥</p>
										{{item.price}}

								  		<!-- <div class="price-icon-wrap">
								  			<img v-if="item.member_price && item.member_price == showPrice(item)" :src="$img('upload/uniapp/index/VIP.png')"/>
								  			<img v-else-if="promotion_type==1" :src="$img('upload/uniapp/index/discount.png')"/>
								  		</div> -->
								  	</div>
								  	<!-- <div class="market-price">￥{{ item.market_price }}</div> -->
								  </div>
								  <div class="sale-num">
								  	<p v-if="item.examine_state==0">未审核</p>
									<p v-if="item.examine_state==1">审核通过</p>
									<p v-if="item.examine_state==2">审核未通过</p>
								  </div>
								  <div class="shop_name">{{item.examine_state_info}}</div>
								  <div class="saling">
								  	<!-- <div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div> -->
								  	<!-- <div class="is_own" v-if="item.is_own == 1">自营</div> -->
								  	<div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
								  </div>
							  </div>
						  		
						  </el-col>
						</el-row>
									
					</div>
				
				
				</div>
			</div>
			<div v-else class="footprint">
				<router-link to="/">暂时没有商品~</router-link>
			</div>
		</el-card>
	</div>
</template>

<script>
    import { footPrint, delFootprint ,myGoods} from "@/api/member/member"
    import { mapGetters } from "vuex"

    export default {
        name: "footprint",
        components: {},
        data: () => {
            return {
                timesArr: [],
                footPrintData: [],
                loading: true,
				cargoList: [],
				yes: true
            }
        },
        created() {
            this.getFootPrintData()
        },
        computed: {
            ...mapGetters(["defaultGoodsImage"])
        },
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
        methods: {
            /**
             * 商品列表
             */
            getFootPrintData() {
                myGoods({
                    page: 1,
                    page_size: 0
                })
                    .then(res => {
                        var data = res.data
						
						// const { count, page_count, list } = res.data
						// this.total = count
						this.cargoList = data
						this.loading = false
						console.log('1000',this.cargoList,res.data);
                        // this.footPrintData = []
                        // this.timesArr = []
                        // for (let i = 0; i < data.length; i++) {
                        //     var date = timeStampTurnTime(data[i].browse_time).split(" ")[0]
                        //     var flag = util.inArray(date, this.timesArr)

                        //     if (flag == -1) {
                        //         this.timesArr.push(date)
                        //     }

                        //     var goods = {}
                        //     goods.id = data[i].id
                        //     goods.sku_id = data[i].sku_id
                        //     goods.browse_time = date
                        //     goods.goods_image = data[i].sku_image.split(",")[0]
                        //     goods.goods_name = data[i].sku_name
                        //     goods.goods_price = data[i].discount_price

                        //     this.footPrintData.push(goods)
                        // }
                        // this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            /**
             * 图片加载失败
             */
            imageError(index) {
                this.footPrintData[index].goods_image = this.defaultGoodsImage
            },
            /**
             * 删除某个足迹
             */
            deleteFootprint(id) {
                delFootprint({
                    id: id
                })
                    .then(res => {
                        this.loading = false
                        this.getFootPrintData()
                        this.$message({ message: res.message, type: "success" })
                    })
                    .catch(err => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            },
            mouseenter(scope) {
                this.$set(scope, "del", true)
            },
            mouseleave(scope) {
                this.$set(scope, "del", false)
            },
			showPrice(data){
				let price = data.discount_price;
				if(data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price;
				return price;
			}
        }
    }
</script>
<style lang="scss" scoped>
	.footprint{
		width: 100%;
		text-align: center;
	}
	.box {
		width: 100%;
		position: relative;
	}
	
	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
    .el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover {
        box-shadow: unset;
    }

    .el-card {
        border: 0;
    }

    .ns-goods-list {
        display: flex;
        flex-wrap: wrap;

        .ns-goods-li {
            width: 32%;
            margin-right: 2%;
            margin-bottom: 20px;
            padding: 15px;
            box-sizing: border-box;
            background-color: #f1f1f1;
            border-radius: 5px;
            cursor: pointer;
            position: relative;

            .el-image {
                width: 100%;
                height: 250px;
            }

            .goods-name {
                margin-top: 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            .goods-price {
                color: $base-color;
                font-size: 20px;
            }

            .ns-btn-del {
                position: absolute;
                top: -6px;
                right: -6px;
                color: #999999;
                cursor: pointer;
                display: none;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;

                i {
                    font-size: 20px;
                }
            }

            &:hover {
                .ns-btn-del {
                    display: inline-block;
                }
            }
        }

        .ns-goods-li:nth-child(3n) {
            margin-right: 0;
        }
    }
	
	.goods-info {
		margin-top: 5px;
		// display: flex;
		// flex-wrap: wrap;
	
		.item {
			// width: 220px;
			margin: 10px 0px 0 0;
			border: 1px solid #eeeeee;
			padding-top: 10px;
			position: relative;
			padding-bottom: 10px;
			cursor: pointer;
			// height: 170px;
	
			&:nth-child(4n) {
				margin-right: initial !important;
			}
	
			&:hover {
				border: 1px solid $base-color;
			}
			
			.img_div{
				border-radius: 8px;
				cursor: pointer;
				height: 100px;
				overflow: hidden;
				position: relative;
				width: 120px;
				// background-color: red;
				margin-left: 10px;
				
				.img-wrap {
					width: 100%;
					height: 100%;
				}
			}
			
	
			.goods-name {
				padding: 0 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
				height: 120px;
				cursor: pointer;
				font-size: 18px;
	
				&:hover {
					color: $base-color;
				}
			}
	
	
			.price_div{
				height: 120px;
				width: 100%;
				text-align: center;
			}
			.price-wrap {
				// padding: 0 10px;
				display: flex;
				align-items: center;
				// padding-top: 40px;
				// padding-left: 20px;
	
				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;
					font-size: 22px;
	
					p {
						font-size: $ns-font-size-base;
						font-size: 22px;
						display: flex;
						align-items: flex-end;
						
					}
				}
	
				.market-price {
					color: #838383;
					text-decoration: line-through;
					// margin-left: 10px;
				}
			}
	
			.sale-num {
				// padding: 0 10px;
				display: flex;
				font-size: 18px;
				color: #838383;
				font-size: $ns-font-size-sm;
	
				p {
					color: #4759a8;
					font-size: 18px;
				}
			}
	
			.shop_name {
				padding: 0 10px;
				display: flex;
				color: #838383;
			}
	
			.saling {
				padding: 0 10px;
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;
	
				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}
	
				.is_own {
					color: #ffffff;
					background: #ff850f;
					border: 1px solid;
					margin-right: 5px;
					display: flex;
					align-items: center;
					padding: 3px 4px;
					border-radius: 2px;
				}
	
				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px 3px;
				}
			}
	
			.item-bottom {
				display: flex;
				margin-top: 5px;
	
				.collection {
					flex: 1;
					border: 1px solid #e9e9e9;
					border-right: none;
					border-left: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
	
				.cart {
					flex: 2;
					border: 1px solid #e9e9e9;
					border-right: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}
</style>
<style lang="scss">
    .foot-print .ns-time-line .el-timeline-item__timestamp.is-top {
        font-size: 18px;
        color: #333333;
    }
</style>
